import { patch } from "../../utils/requests/axios";
import { SET_UI_ERRORS } from "../ui/uiTypes";

import { SET_CART } from "./cartTypes";

export const setCart =
  (product, colorname, size, quantity) => async (dispatch) => {
    try {
      const { data } = await patch(
        `${process.env.REACT_APP_API}/users/cartProducts`,
        {
          product,
          colorname,
          size,
          quantity,
        }
      );

      dispatch({
        type: SET_CART,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: SET_UI_ERRORS,
        payload: { errorsOne: error.response.data.uiErrors },
      });
    }
  };
