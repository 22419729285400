import {
  SET_USER,
  SET_USERS,
  CLEAR_USERS,
  SET_UPDATED_USER,
  SET_USER_LOADED,
  UPDATE_USER,
  DELETE_USER,
} from "./userTypes";

import {
  CLEAR_UI_ERRORS,
  SET_SUCCESS,
  SET_UI_ERRORS,
  SET_UI_LOADING,
} from "../ui/uiTypes";
import axios from "axios";
import { post, get, patch, deleteM } from "../../utils/requests/axios";

import { SET_CART } from "../cart/cartTypes";
import { SET_ADDRESSES } from "../addresses/addressesTypes";
import { batch } from "react-redux";

// ---------------------------- CLEAR APPOINTMENTS ----------------------------
export const clearUsers = () => async (dispatch) => {
  dispatch({
    type: CLEAR_USERS,
  });
};

// ---------------------------- FETCH USERS ----------------------------
export const fetchUsers = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UI_LOADING,
      payload: { fetchLoader: true },
    });

    const { data } = await get(
      `${process.env.REACT_APP_API}/users?_id[ne]=${id}&sort=role`
    );

    batch(() => {
      dispatch({
        type: SET_USERS,
        payload: data.data,
      });
      dispatch({
        type: SET_UI_LOADING,
        payload: { fetchLoader: false },
      });
    });
  } catch (error) {
    console.log(error);
  }
};

// ------------------------ LOG IN ---------------------------
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UI_LOADING,
      payload: { firstLoader: true },
    });

    await post(`${process.env.REACT_APP_API}/users/login`, {
      email,
      password,
    });
    window.location.reload();
  } catch (error) {
    dispatch({
      type: SET_UI_LOADING,
      payload: { firstLoader: false },
    });
    dispatch({
      type: SET_UI_ERRORS,
      payload: { errorsOne: error.response.data.uiErrors },
    });
  }
};

// ------------------------ SIGN UP ---------------------------
export const signUp =
  (name, email, password, passwordConfirm) => async (dispatch) => {
    try {
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: true },
      });

      await post(`${process.env.REACT_APP_API}/users/signup`, {
        name,
        email,
        password,
        passwordConfirm,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: false },
      });
    } catch (error) {
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: false },
      });
      dispatch({
        type: SET_UI_ERRORS,
        payload: { errorsOne: error.response.data.uiErrors },
      });
    }
  };

// ------------------------ LOG OUT ---------------------------
export const logout = () => async () => {
  const { data } = await get(`${process.env.REACT_APP_API}/users/logout`);
  if ((data.status = "success")) {
    sessionStorage.removeItem("page");
    sessionStorage.removeItem("tab");
    window.location.reload();
  }
};

// ------------------------ CHECK LOGGED ---------------------------
export const checkLogged = () => async (dispatch) => {
  dispatch({
    type: SET_USER_LOADED,
    payload: { general: false },
  });
  const { data } = await get(`${process.env.REACT_APP_API}/users/logged`);

  dispatch({
    type: SET_USER,
    payload: data.user,
  });

  if (data.user !== null) {
    dispatch({
      type: SET_CART,
      payload: data.user.productsCart,
    });
    dispatch({
      type: SET_ADDRESSES,
      payload: data.user.addresses,
    });
  }

  dispatch({
    type: SET_USER_LOADED,
    payload: { general: true },
  });
};

// ------------------------ CHECK USER ---------------------------
export const checkUser = () => async (dispatch) => {
  dispatch({
    type: SET_USER_LOADED,
    payload: { tab: false },
  });
  const { data } = await get(`${process.env.REACT_APP_API}/users/logged`);
  dispatch({
    type: SET_USER,
    payload: data.user,
  });

  dispatch({
    type: SET_USER_LOADED,
    payload: { tab: true },
  });
};

// ------------------------ UPDATE USER (name, email) ---------------------------
export const updateMe = (name, photo) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UI_LOADING,
      payload: { firstLoader: true },
    });

    const form = new FormData();

    // form.append('email', email);
    form.append("name", name);
    form.append("photo", photo);

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API}/users/updateMe`,
      form,
      { withCredentials: true }
    );

    batch(() => {
      dispatch({
        type: SET_USER,
        payload: data.user,
      });
      dispatch({
        type: SET_UPDATED_USER,
        payload: true,
      });
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: false },
      });
      // CLEAN UP RUNS IN USE EFFECT OF SETTINGS COMPONENT
      // dispatch({
      //   type: CLEAR_UI_ERRORS,
      // });
    });
  } catch (error) {
    dispatch({
      type: SET_UI_LOADING,
      payload: { firstLoader: false },
    });
    if (error.message === "Network Error") {
      console.log("There was a network error.");
      dispatch({
        type: CLEAR_UI_ERRORS,
      });
      window.location.reload();
    } else {
      if (checkUserAndPermissions(error) === true) {
        dispatch({
          type: SET_UI_ERRORS,
          payload: { errorsOne: error.response.data.uiErrors },
        });
      } else {
        window.location.reload();
      }
    }
  }
};

// ------------------------ CHANGE USER ROLE ---------------------------
export const changeUserRole = (id, role) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UI_LOADING,
      payload: { firstLoader: `user-${id}` },
    });

    const { data } = await patch(
      `${process.env.REACT_APP_API}/users/changeUserRole/${id}`,
      {
        role,
      }
    );

    batch(() => {
      dispatch({
        type: UPDATE_USER,
        payload: data.data,
      });
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: false },
      });
    });
  } catch (error) {
    dispatch({
      type: SET_UI_LOADING,
      payload: { firstLoader: false },
    });
    if (
      error.response.data.message ===
      "You are not logged in! Please log in to get access"
    ) {
      window.location.reload();
    }
  }
};

// ------------------------ CHANGE PASSWORD ---------------------------
export const updateMyPassword =
  (passwordCurrent, password, passwordConfirm) => async (dispatch) => {
    try {
      dispatch({
        type: SET_UI_LOADING,
        payload: { secondLoader: true },
      });

      const { data } = await patch(
        `${process.env.REACT_APP_API}/users/updateMyPassword`,
        {
          passwordCurrent,
          password,
          passwordConfirm,
        }
      );
      batch(() => {
        dispatch({
          type: SET_UI_LOADING,
          payload: { secondLoader: false },
        });
        dispatch({
          type: CLEAR_UI_ERRORS,
        });
        dispatch({
          type: SET_USER,
          payload: data.user,
        });
      });
    } catch (error) {
      dispatch({
        type: SET_UI_LOADING,
        payload: { secondLoader: false },
      });
      if (checkUserAndPermissions(error) === true) {
        dispatch({
          type: SET_UI_ERRORS,
          payload: { errorsTwo: error.response.data.uiErrors },
        });
      } else {
        dispatch({
          type: CLEAR_UI_ERRORS,
        });
        window.location.reload();
      }
    }
  };

// ------------------------ FORGOT PASSWORD ---------------------------
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UI_LOADING,
      payload: { firstLoader: true },
    });

    await post(`${process.env.REACT_APP_API}/users/forgotPassword`, {
      email,
    });
    batch(() => {
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: false },
      });
      dispatch({
        type: CLEAR_UI_ERRORS,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
    });
  } catch (error) {
    batch(() => {
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: false },
      });
      dispatch({
        type: SET_UI_ERRORS,
        payload: { errorsOne: error.response.data.uiErrors },
      });
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });
    });
  }
};

// ------------------------ RESET PASSWORD ---------------------------
export const resetPassword =
  (password, passwordConfirm, token) => async (dispatch) => {
    try {
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: true },
      });

      await patch(`${process.env.REACT_APP_API}/users/resetPassword/${token}`, {
        password,
        passwordConfirm,
      });
      batch(() => {
        dispatch({
          type: SET_UI_LOADING,
          payload: { firstLoader: false },
        });
        dispatch({
          type: CLEAR_UI_ERRORS,
        });
        dispatch({
          type: SET_SUCCESS,
          payload: true,
        });
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      batch(() => {
        dispatch({
          type: SET_UI_LOADING,
          payload: { firstLoader: false },
        });
        dispatch({
          type: SET_UI_ERRORS,
          payload: { errorsOne: error.response.data.uiErrors },
        });
        dispatch({
          type: SET_SUCCESS,
          payload: false,
        });
      });
    }
  };

// ------------------------ RESET PASSWORD ---------------------------
export const verifyAccount = (token) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UI_LOADING,
      payload: { firstLoader: true },
    });

    await patch(`${process.env.REACT_APP_API}/users/verifyAccount/${token}`);
    batch(() => {
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: false },
      });
      dispatch({
        type: CLEAR_UI_ERRORS,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  } catch (error) {
    batch(() => {
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: false },
      });
      dispatch({
        type: SET_UI_ERRORS,
        payload: { errorsOne: error.response.data.uiErrors },
      });
    });
  }
};

// ------------------------ SEND CONTACT MAIL ---------------------------
export const sendContactEmail =
  (name, email, subject, message) => async (dispatch) => {
    try {
      dispatch({
        type: SET_UI_LOADING,
        payload: { firstLoader: true },
      });
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      });

      await post(`${process.env.REACT_APP_API}/users/sendContactMail`, {
        name,
        email,
        subject,
        message,
      });
      batch(() => {
        dispatch({
          type: SET_UI_LOADING,
          payload: { firstLoader: false },
        });
        dispatch({
          type: CLEAR_UI_ERRORS,
        });
        dispatch({
          type: SET_SUCCESS,
          payload: true,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: SET_UI_LOADING,
          payload: { firstLoader: false },
        });
        dispatch({
          type: SET_UI_ERRORS,
          payload: { errorsOne: error.response.data.uiErrors },
        });
      });
    }
  };

// ---------------------------- DELETE USER --------------------
export const deleteUser = (id) => async (dispatch) => {
  try {
    await deleteM(`${process.env.REACT_APP_API}/users/${id}`);
    batch(() => {
      dispatch({
        type: DELETE_USER,
        payload: id,
      });
    });
  } catch (error) {
    if (
      error.response.data.message ===
      "You are not logged in! Please log in to get access"
    ) {
      window.location.reload();
    }
  }
};

// ------------------------------- UTILS --------------------
export const setUpdatedUser = (value) => (dispatch) => {
  dispatch({
    type: SET_UPDATED_USER,
    payload: value,
  });
};

export const checkUserAndPermissions = (error) => {
  // const { message } = error.response.data;
  if (
    error.response.data.message ===
      "You are not logged in! Please log in to get access" ||
    error.response.data.message ===
      "You do not have permission to perform this action" ||
    error.message === "No user"
  ) {
    return false;
  }
  return true;
};

export const checkUserPermissions = (error, dispatch) => {
  if (error.message === "Network Error") {
    console.log("There was a network error.");
    dispatch({
      type: CLEAR_UI_ERRORS,
    });
    window.location.reload();
  } else {
    if (checkUserAndPermissions(error) === true) {
      dispatch({
        type: SET_UI_ERRORS,
        payload: { errorsOne: error.response.data.uiErrors },
      });
    } else if (
      error.response.data.message ===
      "You are not logged in! Please log in to get access"
    ) {
      dispatch({
        type: CLEAR_UI_ERRORS,
      });
      window.location.reload();
    }
  }
};
