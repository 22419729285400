import axios from "axios";

const config = {
  withCredentials: true,
};

export const get = async (url) => {
  const res = await axios.get(url, {
    ...config,
  });
  return res;
};

export const post = async (url, params = {}, extraConfig = {}) => {
  const requestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await axios.post(url, params, {
    ...config,
    ...requestConfig,
    ...extraConfig,
  });
  return res;
};

export const patch = async (url, params = {}, extraConfig = {}) => {
  const requestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await axios.patch(url, params, {
    ...config,
    ...requestConfig,
    ...extraConfig,
  });
  return res;
};

export const deleteM = async (url) => {
  const res = await axios.delete(url, {
    ...config,
  });
  return res;
};
